exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-case-case-tsx": () => import("./../../../src/templates/case/case.tsx" /* webpackChunkName: "component---src-templates-case-case-tsx" */),
  "component---src-templates-industry-industry-tsx": () => import("./../../../src/templates/industry/industry.tsx" /* webpackChunkName: "component---src-templates-industry-industry-tsx" */),
  "component---src-templates-job-job-tsx": () => import("./../../../src/templates/job/job.tsx" /* webpackChunkName: "component---src-templates-job-job-tsx" */),
  "component---src-templates-news-news-tsx": () => import("./../../../src/templates/news/news.tsx" /* webpackChunkName: "component---src-templates-news-news-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-staff-staff-tsx": () => import("./../../../src/templates/staff/staff.tsx" /* webpackChunkName: "component---src-templates-staff-staff-tsx" */)
}

